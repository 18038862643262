<template>
  <div
    v-loading="loading"
    id="order-statistics-order-chart"
    style="height: 300px"
  ></div>
</template>

<script>
import * as API_Distribution from "@/api/distribution";
import echartsOptions from "./echartsOptions";

export default {
  name: "orderStatisticsOrder",
  props: ["params", "curTab"],
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.echarts = this.$echarts.init(
        document.getElementById("order-statistics-order-chart")
      );
    });
  },
  watch: {
    curTab: "GET_OrderStatisticsOrder",
    params: {
      handler: "GET_OrderStatisticsOrder",
      deep: true,
    },
  },
  methods: {
    /** 获取会员下单量 */
    GET_OrderStatisticsOrder() {
      if (this.curTab !== "order" || this.loading) return;
      this.loading = true;
      // this.$emit("update");
      const { cycle_type } = this.params;
      const type_str = cycle_type === "MONTH" ? "日" : "月";
      const _params = {}
      if (cycle_type === 'MONTH') {
        _params.flag_month = 1
        _params.start = +new Date(this.params.year, this.params.month - 1, 1, 0, 0, 0) / 1000
        _params.end = +new Date(this.params.year, this.params.month - 1, new Date(this.params.year, this.params.month, 0).getDate(), 11, 59, 59) / 1000
      } else {
        _params.flag_month = 0
        _params.start = +new Date(this.params.year, 0, 1, 0, 0, 0) / 1000
        _params.end = +new Date(this.params.year, 11, 31, 11, 59, 59) / 1000
      }
      API_Distribution.getOrderNum(_params)
        .then((response) => {
          this.loading = false;
          const { xAxis } = response;
          const series0 = response.series[0];
          const series1 = response.series[1];
          this.echarts.setOption(
            echartsOptions({
              color: ["#c23531", "#2f4554"],
              titleText: `订单数统计（${
                cycle_type === "MONTH" ? "月" : "年"
              }）`,
              legend: {
                right: 50,
                data: [response.series.name],
              },
              xAxisData: xAxis,
              seriesName: "下单数量",
              series: [
                {
                  type: "line",
                  name: response.series.name,
                  data: response.series.data,
                },
              ],
            })
          );
          this.echarts.resize();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
</style>
