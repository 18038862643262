<template>
  <div>
    <div class="info_one">
      <div class="info_title">交易数据</div>
      <en-table-layout :tips="false" :toolbar="false" :tableData="tableData" :loading="loading">
        <!-- 表格数据 -->
        <template slot="table-columns">
          <el-table-column prop="order_total" label="订单数" />
          <el-table-column prop="cumulative_commission" label="佣金金额" />
          <el-table-column label="佣金比例">
            <template slot-scope="scope">{{scope.row.commission_rate}}%</template>
          </el-table-column>
          <el-table-column label="分销等级">
            <template slot-scope="scope">{{scope.row.agent_name}}</template>
          </el-table-column>
          <el-table-column prop="invite_num" label="邀请数" />
          <el-table-column prop="unsettle_money" label="待结算金额" />
          <el-table-column prop="withdrawal_money" label="可提现金额" />
          <el-table-column label="订单情况">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleEdit(scope.row)">查看订单</el-button>
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
      <div class="info_title">佣金统计</div>
      <agent-money/>
    </div>
  </div>
</template>

<script>
import * as API_Distribution from "@/api/distribution";
import AgentMoney from "./agentMoney";
// import { RegExp } from "bms-common/ui-utils";
export default {
  name: "salesDetail",
  components: { AgentMoney },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表数据 */
      tableData: []
    };
  },
  watch: {
    // "addMemberForm.region"(next, prev) {
    //   if (next && next.length) {
    //     this.$refs.addMemberForm.clearValidate("region");
    //   }
    // }
  },
  mounted() {
    this.GET_LevelList();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_LevelList();
    next();
  },
  methods: {
    handleEdit(row) {
      this.$router.push({
        path: `/customer/distribution/distribution-order-list/?mem_id=${this.$route.params.id}`
      });
    },
    /** 获取列表 */
    GET_LevelList() {
      API_Distribution.getDistributorDetailList({
        agent_id: this.$route.params.id
      })
        .then(res => {
          this.tableData = [res];
        })
        .catch(() => {});
    }
  }
};
</script>

<style type="text/scss" lang="scss" scoped>
.info_one {
  border: 1px solid #fff;
  .info_title {
    width: 100%;
    background: #f6f6f6;
    height: 44px;
    line-height: 44px;
    padding-left: 10px;
  }
}
::v-deep .el-dialog {
  width: 30%;
}
::v-deep .el-form-item .el-input {
  width: 150px;
}
.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
</style>
