<template>
  <div class="container">
    <el-card>
      <div slot="header" class="chart-header">
        <div class="chart-header-item">
          <span>查询周期：</span>
          <en-year-month-picker @changed="handleYearMonthChanged" />
        </div>
      </div>
      <el-tabs v-model="cur_tab" type="card">
        <el-tab-pane label="佣金收益统计" name="price">
          <order-statistics-price
            :params="params"
            :cur-tab="cur_tab"
          />
        </el-tab-pane>
        <el-tab-pane label="订单数统计" name="order">
          <order-statistics-order
            :params="params"
            :cur-tab="cur_tab"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import * as API_Distribution from "@/api/distribution";
import orderStatisticsPrice from "./orderStatisticsPrice";
import orderStatisticsOrder from "./orderStatisticsOrder";

export default {
  name: "orderStatistics",
  components: {
    orderStatisticsPrice,
    orderStatisticsOrder,
  },
  data() {
    return {
      cur_tab: "price",
      params: {
        year: "",
        month: "",
        start_time: "",
        end_time: "",
        cycle_type: "MONTH",
        order_status: "",
        seller_id: 0,
      },
    };
  },
  mounted() {
  },
  methods: {
    /** 年月份发生变化 */
    handleYearMonthChanged(object) {
      this.params.year = object.year;
      this.params.month = object.month;
      this.params.cycle_type = object.type;
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.container {
  // background-color: #fff;
  // padding: 10px;
}
.chart-header {
  padding: 0 10px;
}
.chart-header-item {
  display: inline-block;
  margin-right: 30px;
}
.tab-chart {
  height: 300px;
}
</style>
